import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Typography" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h1 {...{
        "id": "paljs-ui-typography"
      }}>{`Pal.js UI Typography`}</h1>
      <p>{`The main goal of typography is to describe how design is applied to letters and text. Pal.js UI Typography is based on `}<a parentName="p" {...{
          "href": "https://eva.design/"
        }}>{`Eva Design System`}</a>{` specification.`}</p>
      <h2 {...{
        "id": "font-family"
      }}>{`Font Family`}</h2>
      <p>{`Pal.js UI theme contains two font-family properties:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`fontFamilyPrimary`}</strong>{` - utilized by all text elements on the page`}</li>
        <li parentName="ul"><strong parentName="li">{`fontFamilySecondary`}</strong>{` - utilized by heading elements (`}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h2>`}</code>{`, etc)`}</li>
      </ul>
      <p>{`By default both fontFamilyPrimary and fontFamilySecondary use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`'Open Sans, sans-serif'`}</code>{` font family names.`}</p>
      <h2 {...{
        "id": "colors"
      }}>{`Colors`}</h2>
      <p>{`There are 5 text colors available within the Design System:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`textBasicColor`}</strong>{` - main text color, should be used on top of basic backgrounds, usually cards, sidebars, headers, available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.text-basic`}</code>{` CSS class`}</li>
        <li parentName="ul"><strong parentName="li">{`textAlternateColor`}</strong>{` - alternative color used on top of alternate backgrounds - colored headers, sidebars, available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.text-alternate`}</code>{` CSS class`}</li>
        <li parentName="ul"><strong parentName="li">{`textControlColor`}</strong>{` - should we used as a text color for status backgrounds (`}<code parentName="li" {...{
            "className": "language-text"
          }}>{`success`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`primary`}</code>{`, etc) - usually buttons, selects , available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.text-control`}</code>{` CSS class`}</li>
        <li parentName="ul"><strong parentName="li">{`textDisabledColor`}</strong>{` - indicates text disabled state, available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.text-disabled`}</code>{` CSS class`}</li>
        <li parentName="ul"><strong parentName="li">{`textHintColor`}</strong>{` - used by secondary texts - captions, placeholders, labels, available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.text-hint`}</code>{` CSS class`}</li>
      </ul>
      <h2 {...{
        "id": "text-styles"
      }}>{`Text Styles`}</h2>
      <p>{`Pal.js UI typography consist of 14 text styles, where text styles is a combination of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-size`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-weight`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`line-height`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-family`}</code>{` properties:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`6 heading`}</strong>{` styles, used by `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{`-`}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{` elements, also available as CSS classes `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.h1`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.h2`}</code>{` ... `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.h6`}</code></li>
        <li parentName="ul"><strong parentName="li">{`2 subtitle`}</strong>{` styles, used as a text for most of the controls (inputs, menus, etc) with classes `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.subtitle`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.subtitle-2`}</code></li>
        <li parentName="ul"><strong parentName="li">{`2 paragraph`}</strong>{` styles for regular text and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` element, with classes `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.paragraph`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.paragraph-2`}</code></li>
        <li parentName="ul"><strong parentName="li">{`2 caption`}</strong>{` styles for smaller text like tooltips and input captions, with classes `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.caption`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.caption-2`}</code></li>
        <li parentName="ul"><strong parentName="li">{`label`}</strong>{` style, used by `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<label>`}</code>{` element as available as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`.label`}</code>{` CSS class`}</li>
        <li parentName="ul"><strong parentName="li">{`button`}</strong>{` text style, used by button element`}</li>
      </ul>
      <h2 {...{
        "id": "apply-text-styles-classes-and-properties"
      }}>{`Apply text styles classes and properties`}</h2>
      <p>{`All of the text styles could be applied by simply adding CSS classes to an element:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`type`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`email`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`name`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`email`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`className`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`caption-2 text-hint`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`Work email address`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code></pre></div>
      <p>{`Here we added both `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`caption-2`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`text-hint`}</code>{` making the span to be a caption with a hint text color.`}</p>
      <p>{`Colors and fonts are also available as theme properties using `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`theme`}</code>{` props in styled components:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` css `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'styled-components'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` newComponent `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` styled`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`div`}<span parentName="code" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`
  `}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token parameter"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{` theme `}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` css`}<span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    .my-text {
      font-family: `}</span><span parentName="span" {...{
                    "className": "token interpolation"
                  }}><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`\${`}</span>{`theme`}<span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`.`}</span>{`fontFamilyPrimary`}<span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`}`}</span></span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`;
      color: `}</span><span parentName="span" {...{
                    "className": "token interpolation"
                  }}><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`\${`}</span>{`theme`}<span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`.`}</span>{`textBasicColor`}<span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`}`}</span></span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`;
    }
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token string"
              }}>{`
`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "customize-typography-styles"
      }}>{`Customize Typography styles`}</h2>
      <p>{`All text styles and colors are available as `}<a parentName="p" {...{
          "href": "/themes/theme-system"
        }}>{`Pal.js UI Theme`}</a>{` properties. This means that all styles could be easily customized by changing theme variables:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  textCaptionFontFamily`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'fontFamilyPrimary'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionFontSize`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.75rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionFontWeight`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`400`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionLineHeight`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'1rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`Complete list of typography variables could be find at`}<a parentName="p" {...{
          "href": "/themes/default"
        }}>{` Default Theme Variables`}</a>{` page.`}</p>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      